import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Auth } from 'aws-amplify';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    beforeEnter: (_to, _from, next) => {
      Auth.currentAuthenticatedUser()
        .then(() => next({ name: 'repositories' }))
        .catch(() => next());
    },
  },
  {
    path: '/protected',
    name: 'Protected',
    component: () => import(/* webpackChunkName: "protected" */ '../views/Protected.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/repositories',
    name: 'repositories',
    component: () => import(/* webpackChunkName: "protected" */ '../views/Repositories.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/repositories/:uuid',
    name: 'repository',
    component: () => import(/* webpackChunkName: "protected" */ '../views/Repository.vue'),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Handling AuthN
router.beforeEach((to, _from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser()
      .then(() => next())
      .catch(() => next({ name: 'login' }));
  }
  next();
});

export default router;
