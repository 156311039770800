import { StoreRepositoryState, StoreRepository } from '@/types';

export const SET_REPOSITORIES = 'SET_REPOSITORIES';
export const SET_CURRENT_REPOSITORY = 'SET_CURRENT_REPOSITORY';
export const SET_CURRENT_REPOSITORY_FILES = 'SET_CURRENT_REPOSITORY_FILES';
export const SET_REPOSITORY_USERS = 'SET_REPOSITORY_USERS';

export default {
  [SET_REPOSITORIES]: (state: StoreRepositoryState, payload: StoreRepository[]) => {
    state.list = payload;
  },
  [SET_CURRENT_REPOSITORY]: (state: StoreRepositoryState, payload: string) => {
    state.current.uuid = payload;
  },
  [SET_CURRENT_REPOSITORY_FILES]: (state: StoreRepositoryState, payload: any[]) => {
    state.current.files = payload;
  },
  [SET_REPOSITORY_USERS]: (state: StoreRepositoryState, payload: any[]) => {
    state.current.users = payload;
  },
};
