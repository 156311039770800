import { Commit, Dispatch } from 'vuex';
// import { StoreRepositoryState } from '@/types';
import {
  SET_REPOSITORIES,
  SET_CURRENT_REPOSITORY,
  SET_CURRENT_REPOSITORY_FILES,
  SET_REPOSITORY_USERS,
} from './mutations';

import {
  getRepositories,
  getFilesByRepository,
  postRepository,
  getUsersByRepository,
  deleteUsersFromRepository,
} from '@/api';

export default {
  setRepositories: async ({ commit }: { commit: Commit }) => {
    const repositories = await getRepositories();
    commit(SET_REPOSITORIES, repositories);
  },
  setCurrentRepository: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    repositoryUuid: string
  ) => {
    // As for now we get current repository from store repositories
    // Should we need to call an endpoint dedicated to the single repository
    // We would implement a dedicated API call
    const files = await getFilesByRepository(repositoryUuid);
    await dispatch('setRepositoryUsers', repositoryUuid);

    commit(SET_CURRENT_REPOSITORY, repositoryUuid);
    commit(SET_CURRENT_REPOSITORY_FILES, files);
  },
  setRepositoryUsers: async ({ commit }: { commit: Commit }, repositoryUuid: string) => {
    const users = await getUsersByRepository(repositoryUuid);

    commit(SET_REPOSITORY_USERS, users);
  },
  createNewRepository: async ({ dispatch }: { dispatch: Dispatch }, repository: any) => {
    const response = await postRepository(repository);
    console.log('CREATE NEW REPO', response);
    dispatch('setRepositories');
  },
  removeUsersFromRepository: async (_actions: {}, payload: { repositoryUuid: string; users: any[] }) => {
    await deleteUsersFromRepository(payload.repositoryUuid, payload.users);
  },
};
