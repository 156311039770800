import { Commit } from 'vuex';
import { SET_USERS } from './mutations';
import { getUsers } from '@/api';

export default {
  setUsers: async ({ commit }: { commit: Commit }) => {
    const users = await getUsers();
    commit(SET_USERS, users);
  },
};
