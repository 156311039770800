import { API } from 'aws-amplify';
import axios from 'axios';
import { FileToPost } from '@/types';

export const getRepositories = async () => {
  try {
    const repositories = await API.get(process.env.VUE_APP_AWS_API_NAME, '/repositories', {});
    return repositories;
  } catch (error) {
    console.error(error);
  }
};

export const getFilesByRepository = async (repositoryUuid: string) => {
  try {
    const files = await API.get(process.env.VUE_APP_AWS_API_NAME, `/repositories/${repositoryUuid}/files`, {});
    return files;
  } catch (error) {
    console.error(error);
  }
};

export const getUsersByRepository = async (repositoryUuid: string) => {
  try {
    const users = await API.get(process.env.VUE_APP_AWS_API_NAME, `/repositories/${repositoryUuid}/users`, {});
    return users;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUsersFromRepository = async (repositoryUuid: string, users: any[]) => {
  try {
    const response = await API.del(process.env.VUE_APP_AWS_API_NAME, `/repositories/${repositoryUuid}/users`, {
      body: users,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const postRepository = async (repository: any) => {
  try {
    const response = await API.post(process.env.VUE_APP_AWS_API_NAME, '/repositories', { body: repository });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const postFileToRepository = async (repositoryUuid: string, file: FileToPost | null) => {
  try {
    const response = await API.post(process.env.VUE_APP_AWS_API_NAME, `/repositories/${repositoryUuid}/files`, {
      body: file,
    });
    return response;
  } catch (error) {
    console.error('ERRORE POST FILE TO REPO', error);
  }
};

export const deleteFileFromRepository = async (repositoryUuid: string, fileUuid: string) => {
  try {
    const response = await API.del(
      process.env.VUE_APP_AWS_API_NAME,
      `/repositories/${repositoryUuid}/files/${fileUuid}`,
      {}
    );
    return response;
  } catch (error) {
    console.error('ERRORE DELETE FILE FROM REPO', error);
  }
};

export const deleteRepository = async (repositoryUuid: string) => {
  try {
    const response = await API.del(process.env.VUE_APP_AWS_API_NAME, `/repositories/${repositoryUuid}`, {});
    return response;
  } catch (error) {
    console.error('ERRORE DELETE REPO', error);
  }
};

export const postUsersToRepository = async (repositoryUuid: string, users: any[]) => {
  try {
    const response = await API.post(process.env.VUE_APP_AWS_API_NAME, `/repositories/${repositoryUuid}/users`, {
      body: users,
    });
    return response;
  } catch (error) {
    console.error('ERRORE POST USERS TO REPO', error);
  }
};

export const putFileToS3SignedURL = async (signedURL: string, file: any) => {
  try {
    const response = await axios.put(signedURL, file);
    return response;
  } catch (error) {
    console.error('PUT FILE ERROR', error);
  }
};

export const getFileInformation = async (repositoryUuid: string, fileUuid: string) => {
  try {
    const response = await API.get(
      process.env.VUE_APP_AWS_API_NAME,
      `/repositories/${repositoryUuid}/files/${fileUuid}`,
      {}
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUsers = async () => {
  try {
    const users = await API.get(process.env.VUE_APP_AWS_API_NAME, '/users', {});
    return users;
  } catch (error) {
    console.error(error);
  }
};
