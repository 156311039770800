import Vue from 'vue';
import Vuex from 'vuex';
import repositories from './repositories';
import user from './user';
import users from './users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    repositories,
    user,
    users,
  },
});
