












import Vue from 'vue';
import { mapActions } from 'vuex';
import { Hub, Auth } from 'aws-amplify';

import Navbar from '@/components/Navbar.vue';

export default Vue.extend({
  components: {
    Navbar,
  },
  data() {
    return {
      isUserSignedIn: false,
    };
  },
  created() {
    // Listen on auth events
    Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          console.log('SIGNED IN HUB');
          this.isUserSignedIn = true;
          this.$router.push({ name: 'repositories' });
          break;
        case 'signOut':
        case 'signIn_failure':
          console.log('SIGNED out / failure HUB');
          this.isUserSignedIn = false;
          this.$router.push({ name: 'home' });
          break;
        case 'configured':
          try {
            await Auth.currentAuthenticatedUser();
            this.isUserSignedIn = true;

            console.log('CONFIGURED HUB');
          } catch (e) {
            // this.signedIn = false
            this.isUserSignedIn = false;
          }
      }
    });
  },
  async mounted() {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      // const session = await Auth.currentSession();

      this.setUser(authenticatedUser);
      this.$router.push({ name: 'repositories' });
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    ...mapActions('user', ['setUser']),
  },
});
