import { Commit } from 'vuex';
import { CognitoUser } from '@aws-amplify/auth';

import { SET_USER } from './mutations';

export default {
  setUser: ({ commit }: { commit: Commit }, user: CognitoUser) => {
    commit(SET_USER, user);
  },
};
